<div class="main-console-container" >

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        {{niceOtpName}} One-Time Passcode
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">

        <div class="h-100" *ngIf="multiFactor">
          <app-multi-credentials class="h-100" [errorMsg]="errorMsg" [credentialOptions]='otpOptions' buttonLabel='Continue'
                                 (selectedCredential)="selectedCredential($event)"></app-multi-credentials>
        </div>

        <form *ngIf="!multiFactor" class="h-100" [formGroup]="verifyOtpForm" novalidate
              [style.visibility]="ready? 'visible' : 'hidden'">

          <div>
            <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <div>
              <div *ngIf="otpSent">
                <span class="align-middle">Enter security code sent to {{selectedCredValue}}</span>
                <img src="assets/img/help-icon.svg" title="One-time passcode sent via {{niceOtpName}}" alt="Passcode delivery method"
                     class="tooltip-icon"/>
              </div>
              <span class="visible align-middle" *ngIf="!otpSent">{{statusMsg}}</span>
            </div>
          </div>

          <div class="mt-2">
            <div class="row">
              <app-otp-input (otpEntryChange)="onOtpEntryChange($event)" [ready]="ready"></app-otp-input>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col" *ngIf="resendEnabled">
              <button type="submit" class="primary-btn" (click)="onResendOTP()">
                <fa-icon [icon]="resendIcon" class="pe-2 text-white" title="Resend Icon"></fa-icon>
                Resend
              </button>
            </div>
            <div class="col ms-auto" *ngIf="factorSelection">
              <button class="secondary-btn" (mousedown)="onBack()" *ngIf="factorSelection">
                More Options
              </button>
            </div>
          </div>

          <div>
            <app-userinfo></app-userinfo>
            <app-loading *ngIf="!ready && !errorDiv"></app-loading>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
