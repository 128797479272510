<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Logout Successful
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <div class="h4 text-wrap mb-0">
          <fa-icon [icon]="leaveIcon" class="pe-2 d-inline"></fa-icon>
          Signed out of your account.
        </div>

        <div class="my-4">
          Thank you for using AuthHub. You can log back into your account at any time.
        </div>

        <form novalidate>
          <button id="login-btn" type="login" class="primary-btn align-bottom container-fluid mb-0" routerLink="/direct">
            Log in again
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
