<div class="main-console-container">

  <div class="row">

    <div class="col-md-6">
      <div class="main-console-title">
        Access Denied
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        If you have legitimate need to access this system, please use the information from links below to resolve.
        <div class="row my-3">
          <div class="col-md-6">
            <a routerLink="/help" class="reference">Login Assistance</a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
