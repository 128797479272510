import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import { GlobalService } from '../service/global.service';
import { MotpService } from '../service/motp.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-motp',
  templateUrl: './motp.component.html',
  styleUrls: ['./motp.component.scss']
})
export class MotpComponent implements OnInit, OnDestroy {
  multiFactor: boolean = false;
  ready: boolean = false;
  registerMobileOtp: boolean = false;
  credId = '';
  mobileOtpOptions: string[] = [];
  verifyMobileOtpForm: UntypedFormGroup;

  errorDiv: boolean = false;
  errorMsg: String;
  factorSelection: boolean = false;
  selectedCredValue: string;
  credValue: string;
  otpEntry: string;
  pageSubs: Subscription[] = new Array<Subscription>();
  isRegisterFlow: boolean;
  qrData: string;



  constructor(private factorselectionService: FactorselectionService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private motpService: MotpService,
              private displayService: DisplayService,
              public globalService: GlobalService) {
  }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.verifyMobileOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
      sharedSecret: ['']
    })
    this.registerMobileOtp = this.displayService.registerMobileOtp;

    this.isRegisterFlow = location.href.endsWith("register/totp");

    if (this.isRegisterFlow) {
      this.registerFlow();
    } else {
      this.factorFlow();
    }
  }

  private factorFlow() {
    this.mobileOtpOptions = this.displayService.otpOptions;
    this.factorSelection = this.displayService.factorSelection;
    this.mobileOtpOptions.forEach(function (value) {
      if (value["default"] === true) {
        this.credId = value["credId"];
        this.selectedCredValue = value["credValue"];
      }
    }.bind(this));
    if (this.mobileOtpOptions.length === 1) {
      this.credId = (<any>this.mobileOtpOptions[0]).credId;
      this.selectedCredValue = (<any>this.mobileOtpOptions[0]).credValue;
      this.onMobileOTPGenerate();
      this.multiFactor = false;
    } else {
      this.multiFactor = true;
    }
  }

  private registerFlow() {
    this.multiFactor = false;
    this.factorSelection = true;

    this.makeQrCode();
    this.ready = true;
  }

  private makeQrCode() {
    let data = this.router.lastSuccessfulNavigation?.extras?.state?.data;

    this.credId = data["credId"];
    this.credValue = data["credValue"];
    this.selectedCredValue = this.credValue;
    let userName = this.displayService.userName;
    let tenantName = data["tenantName"];
    let issuer = tenantName;
    if (tenantName==="default" || tenantName==="common") {
      let hostComponents = window.location.hostname.split(".");
      if (hostComponents[1]==="dev" ||
        hostComponents[1]==="test" ||
        hostComponents[1]==="qa") {
        issuer = "BNYM " + hostComponents[1].toUpperCase();
      } else if(hostComponents[1]==="bnymellon") {
        issuer = "BNY Mellon";
      } else {
        issuer = tenantName;
      }
    }
    let sharedSecret = "otpauth://totp/" + userName;
    sharedSecret += "?secret=" + data["sharedSecret"] + "&issuer=" + issuer;
    this.qrData = sharedSecret;
  }

  selectedCredential(event: any) {
    if (event) {
      this.credId = event.credId;
      this.selectedCredValue = event.credValue;
    }
    this.onMobileOTPGenerate();
  }

  onMobileOTPGenerate() {
    this.ready = true;
    this.multiFactor = false;
  }

  onMobileOTPVerify() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs = [this.verityAuthMobileOtp()];
  }

  verityAuthMobileOtp() {
    return this.motpService.verifyMobileOtp(this.otpEntry, this.credId, this.selectedCredValue, this.isRegisterFlow)
      .subscribe({
        next: (data) => {
          if (this.isRegisterFlow) {
            this.router.navigateByUrl("self-service", {state: {newCredAdded: true, newCredName: this.selectedCredValue}});
          } else {
            this.displayService.routeActions(data);
          }
        },
        error: (err) => {
          this.errorDiv = this.displayService.errorDiv;
          this.errorMsg = this.displayService.errorMsg;
          this.ready = true;
        }
      })
  }

  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  onOtpEntryChange(event) {
    this.otpEntry = event.otpInput;
    if (event.submit) {
      this.onMobileOTPVerify();
    }
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
