<h2 mat-dialog-title class="dialog-header">Confirm Changes</h2>
<mat-dialog-content>
  <div class="row gx-0">
    {{data.message}}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container gx-0">
    <div class="row g-2 justify-content-between">
      <div class="col-6 ps-0">
        <button mat-button *ngIf="data.btnType" name="Submit Button" class="primary-btn" cdkFocusInitial (click)="onSubmit()">{{data.btnType || "Next"}}</button>
      </div>
      <div class="col-6 pe-0">
        <button mat-button name="Cancel Button" class="secondary-btn" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
