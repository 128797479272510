<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Restart Required Notice
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
          <div>
            <app-errormsg class="mb-2" [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <div>Unrecoverable error. Please restart login or try again later.</div>
          </div>

          <div class="my-2">
            <div *ngIf="!isOauthFlow" class="primary-btn gx-0 pointerOnHover" (click)="restartAuthFlow()">
              Click to retry login
            </div>
          </div>

          <app-userinfo></app-userinfo>
      </div>
    </div>
  </div>
</div>
