import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';
import {decode} from "../shared/base64";

@Injectable({
  providedIn: 'root'
})
export class FidoregService {

  constructor(private http: HttpClient, private displayService: DisplayService) {
  }

  public activationGenerateChallenge(device: string, type: string) {
    this.displayService.fidoCredType = type.toUpperCase();
    const postData = {
      userName: this.displayService.userName,
      deviceName: device,
      documentDomain: location.origin,
      fidoCredType: this.displayService.fidoCredType,
      userAccessToken: true,
      noFlowState: true
    };

    return this.http.post(this.displayService.serviceUrl + 'factor/v1/FIDORegChallengeGenerator', postData);
  }

  public sendRegistrationVerifyChallenge(makeCredReq, type) {
    this.displayService.fidoCredType = type.toUpperCase();
    makeCredReq["fidoCredType"] = this.displayService.fidoCredType;
    makeCredReq.userAccessToken = true;
    makeCredReq.noFlowState = true;
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/FIDORegChallengeVerifier', makeCredReq);
  }

  createNavCredFromData(regData) {
    if (sessionStorage.getItem("__authn_debug000")) {
      let option = sessionStorage.getItem("__authn_debug000");
      if (("" + option) === ("" + window["RSWF"])) {
        regData["authenticatorSelection"]["authenticatorAttachment"] = "platform";
      } else if (("" + option) === ("" + window["RFWS"])) {
        regData["authenticatorSelection"]["authenticatorAttachment"] = "cross-platform";
      }
    }

    regData.challenge = decode(regData.challenge);
    regData.user.id = decode(regData.user.id);
    if (regData.excludeCredentials && regData.excludeCredentials.length > 0) {
      for (let i = 0; i < regData.excludeCredentials.length; i++) {
        regData.excludeCredentials[i].id = decode(regData.excludeCredentials[i].id);
      }
    }
    return navigator.credentials.create({ publicKey: regData });
  }

}
