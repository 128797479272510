<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        About the Authentication Hub
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
      <span>
        <p>AuthHub is a web authentication service which provides an authentication portal to Siteminder. The service offers increased security and support for passwordless authentication.</p>

        New authentication factors
        <ul>
          <li>SMS OTP</li>
          <li>Phone OTP</li>
          <li>Email OTP</li>
          <li>Push Notifications (<a class="reference" href="https://www.rfc-editor.org/rfc/rfc8599">RFC-8599</a>)</li>
          <li>Time-based OTP (<a class="reference" href="https://www.rfc-editor.org/rfc/rfc6238">RFC-6238</a>)</li>
        </ul>

        Supported FIDO protocol factors (<a class="reference" href="https://fidoalliance.org/">FIDO alliance</a>)
        <ul>
          <li>Biometrics</li>
          <li>Security Keys</li>
        </ul>

        Legacy authentication factors
        <ul>
          <li>Password</li>
          <li>RSA SecurID</li>
        </ul>

      </span>
      </div>
    </div>
  </div>
</div>
