<form class="h-100 container" [style.visibility]="ready? 'visible' : 'hidden'">
  <div>
    <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
    <div class="o-form-explain">Select a way to sign in</div>
  </div>


  <!-- All radio buttons -->
  <div>
    <div class="row channelSelection" *ngFor="let cred of credentialOptions">
      <label class="container col">
        <mat-radio-button name="radiogroup" [checked]="cred.default" (change)="onSelect(cred)" >
          {{cred.credValue}}
        </mat-radio-button>
        </label>
      </div>
    </div>

  <div id="outer">
    <div class="row mt-3">
      <div class="inner col"> <button class="primary-btn" [disabled]="!credential || clicked" (click)="onSendCode()">{{buttonLabel?buttonLabel:'Send Code'}}</button></div>
      <div class="inner col"> <button class="secondary-btn" (mousedown)="factorSelect()" *ngIf="factorSelection">More Options</button></div>
    </div>
  </div>

  <div>
    <app-userinfo></app-userinfo>
    <app-loading *ngIf="!ready && !errorDiv"></app-loading>
  </div>
</form>
