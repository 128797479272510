<div class="main-console-container">
  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Update Password Required
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">

        <form class="h-100" novalidate [style.visibility]="ready? 'visible' : 'hidden'"
              [formGroup]="passwordChangeForm">

          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg" class="mb-2"></app-errormsg>

          <div class="mb-1">
            <!-- Current password -->
            <div><span> Enter your current password.</span></div>
            <app-password-input id="currentPassword" name="Current password" [control]="$any(currPwdCtrl)"
                                [invalidCondition]="passwordChangeForm?.errors?.reuseOld"
                                (keyup.enter)="onEnter($event, 1)"></app-password-input>
            <!-- Current password cannot match new password -->
            <app-errormsg [errorDiv]="passwordChangeForm?.errors?.reuseOld" [enableMoreInfo]=false
                          errorMsg="* New and old passwords cannot match."></app-errormsg>
            <!-- Field is required. Hide this error until form is submitted or field is dirty -->
            <app-errormsg [errorDiv]="currPwdCtrl.dirty && currPwdCtrl.errors?.required" [enableMoreInfo]=false
                          errorMsg="* Field is required."></app-errormsg>
          </div>

          <hr/>

          <div class="mb-3">
            <!-- New password -->
            <div><span> Enter your new password.</span></div>
            <app-password-input id="newPassword" [control]="$any(newPwdCtrl)" name="New password"
                                [invalidCondition]="passwordChangeForm?.errors?.reuseOld"
                                (keyup.enter)="onEnter($event, 2)"></app-password-input>
            <!-- New password cannot match current password -->
            <app-errormsg [errorDiv]="passwordChangeForm?.errors?.reuseOld" [enableMoreInfo]=false
                          errorMsg="* New and old passwords cannot match."></app-errormsg>
            <!-- Field is required. Hide this error until form is submitted or field is dirty -->
            <app-errormsg [errorDiv]="newPwdCtrl.dirty && newPwdCtrl.errors?.required" [enableMoreInfo]=false
                          errorMsg="* Field is required."></app-errormsg>
          </div>

          <div>
            <!-- Confirm new password -->
            <div><span>Confirm your new password.</span></div>
            <app-password-input id="confirmNewPassword" [control]="$any(confirmNewPwdCtrl)" name="Confirm new password"
                                [invalidCondition]="passwordChangeForm?.errors?.passwordMismatch"
                                (keyup.enter)="onEnter($event)"></app-password-input>
            <!-- Confirmed password must match new password -->
            <app-errormsg [errorDiv]="(confirmNewPwdCtrl.dirty) && passwordChangeForm.errors?.passwordMismatch" [enableMoreInfo]=false
                          errorMsg="* Confirmed password does not match."></app-errormsg>
            <!-- Field is required. Hide this error until form is submitted or field is dirty -->
            <app-errormsg [errorDiv]="confirmNewPwdCtrl.dirty && confirmNewPwdCtrl.errors?.required" [enableMoreInfo]=false
                          errorMsg="* Field is required."></app-errormsg>
          </div>

          <div class="row mt-4">
            <div class="col">
              <button id="submit-btn" class="primary-btn" type="button" (click)="onFormSubmit()">
                Submit
              </button>
            </div>
            <div class="col ms-auto" *ngIf="factorSelection">
              <button class="secondary-btn" (mousedown)="factorSelect()">
                More Options
              </button>
            </div>
          </div>

          <div>
            <app-userinfo></app-userinfo>
            <app-loading *ngIf="!ready && !errorDiv"></app-loading>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
