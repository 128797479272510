export class CredType {
  type: string;
  rank: number;
  name: string;
  regName?: string;
  label?: string;
  legalNotice?: string = null;
  // SMS and Email registration is disabled, until personal and corporate devices are differentiable.
  disableReg?: boolean;
}

export let CredTypeMap: {[id: string]: CredType} =  {
    sms: {
      type: "sms", name: "SMS", rank: 1, label: "phone number starting with country code", disableReg: true,
      legalNotice: "By providing your phone number, you agree to receive text messages from \"BNY Mellon Corp\". Message and data rates may apply. Message frequency varies."
    },
    email: {
      type: "email", name: "Email", rank: 2, label: "email address", disableReg: true,
      legalNotice: "By providing your email address, you agree to receive emails from \"BNY Mellon Corp\""
    },
    fido: {
      type: "fido", name: "Biometric", rank: 3
    },
    securitykey: {
      type: "securitykey", name: "Security key", rank: 4
    },
    totp: {
      type: "totp", name: "Mobile OTP", rank: 5, regName: "Code from Mobile Authenticator"
    },
    totp_push: {
      type: "totp_push", name: "VIP Push and OTP", regName: "VIP Access", rank: 6
    }
}
