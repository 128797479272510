<div class="main-console-container" >

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        <img src="assets/img/RSA_NewLogo_White-RGB.png" class="securid-label" alt="RSA SecurID Logo"/>
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">

        <form class="h-100" [formGroup]="securidForm" novalidate [style.visibility]="ready ? 'visible' : 'hidden'">
          <div>
            <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <span>Enter your pin and passcode</span>
            <img src="assets/img/help-icon.svg" title="Use your pin and the passcode from the RSA Authenticator app" alt="SecurID tooltip" class="tooltip-icon"/>
          </div>

          <div>
            <app-otp-input [hideEntry]="true" inputSize="10" (otpEntryChange)="onOtpEntryChange($event)" [ready]="ready"></app-otp-input>
          </div>

          <div class="row mt-3">
            <div class="col">
              <button class="secondary-btn" (mousedown)="factorSelect()" *ngIf="factorSelection">
                More Options
              </button>
            </div>
          </div>

          <div>
            <app-userinfo></app-userinfo>
          </div>
        </form>

        <app-loading *ngIf="!ready && !errorDiv"></app-loading>

      </div>
    </div>
  </div>
</div>
