import {Component, OnDestroy, OnInit} from '@angular/core';
import {DisplayService} from "../service/display.service";
import {TokenService} from "../service/token.service";
import {AuthState, AuthStateService} from "../service/auth-state.service";
import {GlobalService} from "../service/global.service";
import {Subscription} from "rxjs";
import {ResetService} from "../service/reset.service";
import {InitService} from "../service/init.service";
import {RememberInfoService} from "../service/rememberInfo.service";

@Component({
  selector: 'app-idp-complete',
  templateUrl: './idp-complete.component.html'
})
export class IdpCompleteComponent implements OnInit, OnDestroy {

  idpData: Object;
  errorDiv: boolean = false;
  errorMsg: string;
  userName: string;
  rememberMe: boolean;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private displayService: DisplayService,
              private rememberService: RememberInfoService,
              private tokenService: TokenService,
              private authStateService: AuthStateService,
              private globalService: GlobalService,
              private resetService: ResetService,
              private initService: InitService) {
  }

  ngOnInit(): void {
    this.globalService.disableUserReset();
    this.authStateService.setAuthStatusFactor();

    // Determine idp auth state
    this.resetService.setQueryParams();
    if (this.resetService.queryParams.get("isIDP") == "true") {
      this.idpFactorFlow();
    } else if (this.resetService.queryParams.get("x-flow-state") != null) {
      this.idpDiscoveryFlow();
    } else {
      this.displayService.errorMsg = "Unexpected response from identity provider.";
      this.authStateService.setAuthStatusRestartNeeded()
    }
  }

  /**
   * Continue IDP factor authentication flow
   * @private
   */
  private idpFactorFlow() {
    this.getIdpData();
    if (this.authStateService.getAuthStatus() != AuthState.RESTART_NEEDED) {
      // If getting IDP data failed, then do not continue. Redirecting takes small amount of time
      this.determineLoginState();
    }
  }

  private getIdpData() {
    try {
      let idpDataStr = sessionStorage.getItem("idpData");
      this.idpData = JSON.parse(idpDataStr);
      sessionStorage.removeItem("idpData");
      this.recoverFlowData();
    } catch (ex) {
      this.displayService.errorMsg = "Unable to parse identity provider results."
      this.displayService.errorDiv = true;
      this.idpData = null;
      this.authStateService.setAuthStatusRestartNeeded();
    }
  }

  private recoverFlowData() {
    this.displayService.userName = this.idpData['userName'];
    this.displayService.flowState = this.idpData['flowState'];
    this.globalService.setUserName(this.displayService.userName);
    this.displayService.updateCurrentFactorLevel(this.idpData);
  }

  private getUat(idpData) {
    // Get user access token
    this.pageSubs.push(
      this.tokenService.getUserAccessToken(idpData['id_token']).subscribe({
        next: (uatResponse) => {
          delete idpData['id_token'];
          let combinedData = {...uatResponse, ...idpData};
          this.displayService.routeActions(combinedData)
        },
        error: (err) => {
          this.authStateService.setAuthStatusRestartNeeded();
        }
      })
    );

  }

  private getCat(idpData) {
    // Get user access token
    this.pageSubs.push(
      this.tokenService.getClientAccessToken().subscribe({
        next: (catResponse) => {
          this.authStateService.clientAccessToken = catResponse['access_token'];
          let combinedData = {...catResponse, ...idpData};
          this.displayService.routeActions(combinedData)
        },
        error: (err) => {
          this.authStateService.setAuthStatusRestartNeeded();
        }
      })
    );
  }


  private determineLoginState() {
    if (this?.idpData['nextaction'] === "AUTH_ALLOWED") {
      // Determine which kind of successful auth
      if (this?.idpData['id_token']) {
        // ID Token implies direct auth success. Get User AT and continue
        this.getUat(this.idpData);
      } else if (this?.idpData['authCompleteUrl']) {
        // authCompleteUrl implies oauth2 auth success. Invoke successful callback URL
        this.displayService.routeActions(this.idpData);
      } else {
        // Fail if ID token and authCompleteUrl are missing
        this.displayService.errorMsg = "Missing information from identity provider response data.";
        this.displayService.errorDiv = true;
        this.authStateService.setAuthStatusRestartNeeded();
      }
    } else  {
      // For all other cases, get client AT and continue
      this.getCat(this.idpData);
    }
  }

  private idpDiscoveryFlow() {
    this.pageSubs.push(
      // Get new OAuth2 client token
      this.tokenService.getClientAccessToken().subscribe({
        next: (data) => {
          this.errorDiv = false;
          this.authStateService.clientAccessToken = data['access_token'];
          this.onLogin();
        },
        error: (err) => {
          this.authStateService.setAuthStatusRestartNeeded()
        }
      })
    );
  }

  onLogin() {
    if (this.userName) {
      this.globalService.setUserName(this.userName);
    }
    if (this.rememberMe) {
      this.rememberService.setUsername(this.userName);
    }
    this.reinitiateAuthFlow();
  }

  ngOnDestroy(): void {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private reinitiateAuthFlow() {
    // Initial authenticate call to get username & next action
    this.initService.authenticate(null, this.rememberMe).subscribe({
      next: (data) => {
        // ID propagation - submit existing user if found
        if (data?.nextaction && data?.userName) {
          // When username is already set
          // Disable username resubmission when ID is propagated
          this.globalService.disableUserReset();
          this.displayService.routeActions(data);
        } else {
          // No id propagation found, then fail
          this.displayService.errorMsg = "Unable to locate user information from IDP response."
          this.authStateService.setAuthStatusRestartNeeded()
        }
      },
      error: (err) => {
        // ID propagation call failed
        this.displayService.errorMsg = "Unable to locate user information from IDP response."
        this.authStateService.setAuthStatusRestartNeeded()
      }
    });
  }
}
