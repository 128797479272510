<div class="main-console-container">

  <div class="row gy-md-2">

    <div class="col-12">
      <div id="welcome-user" class="main-console-title">
        Hello, {{userName}}
      </div>
    </div>

    <div class="col-12">
      <div class="main-console-card">
        Here you can access the VIP Authentication Hub self service tools.
        <div class="row my-3">
          <div class="col-md-6 mb-2 m-md-0">
            <a class="reference" routerLink="/self-service">
              Manage Credentials
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
