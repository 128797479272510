<div class="main-console-container" *ngIf="passwordEnabled">
  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Password Login
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <form class="h-100" [formGroup]="passwordForm" novalidate [style.visibility]="ready? 'visible' : 'hidden'">

          <div class="mb-2">
            <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <span>Enter your password</span>
          </div>

          <div>
            <input id="password-in" type="password" formControlName="password" placeholder="Password" class="form-control user-input"/>
            <div *ngIf="passwordForm.controls['password'].invalid && passwordForm.controls['password'].dirty"
                 class="alert alert-danger move-up mb-0">
              <div *ngIf="passwordForm.controls['password'].errors?.required" class="alert-red">
                Password is required.
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <button id="submit-btn" [disabled]="passwordForm.controls['password'].invalid" class="primary-btn"
                      (click)="onPasswordSubmit()">
                Submit
              </button>
            </div>
            <div class="col ms-auto" *ngIf="factorSelection">
              <button class="secondary-btn" (mousedown)="factorSelect()">
                More Options
              </button>
            </div>
          </div>

          <div>
            <app-userinfo></app-userinfo>
            <app-loading *ngIf="!ready && !errorDiv"></app-loading>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
