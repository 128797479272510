<div class="main-console-container">
  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
          A Better Login Experience
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <form class="flex-form"  [formGroup]="loginForm" novalidate [style.visibility]="ready?  'visible' : 'hidden'">

          <div>
            <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <span>Enter User ID</span>
          </div>

          <div>
            <input id="username-in" type="text" formControlName="userName" placeholder="User ID" class="form-control user-input mt-1">

            <label class="checkbox-label row mt-1 mb-4" for="rememberMe">
              <mat-checkbox disableRipple value="true"[checked]="true" id="rememberMe" name="rememberMe" formControlName="rememberMe">
                Remember Me
                <img src="assets/img/help-icon.svg" title="Your User ID and device will be saved for future logins." alt="Remember me"
                     class="tooltip-icon"/>
              </mat-checkbox>
            </label>
          </div>

          <div>
            <button id="submit-btn" type="submit" [disabled]="loginForm.controls['userName'].invalid" (click)="onLogin()"
                    class="primary-btn container-fluid">
              <strong>Submit</strong>
            </button>
          </div>

          <div>
            <app-userinfo *ngIf="!ready && userName && !initialSubmit" (resetEmitter)="resetUser($event)"></app-userinfo>
            <app-loading *ngIf="!ready && !errorDiv"></app-loading>
          </div>

        </form>

      </div>
    </div>
    <div class="col-12">
      <div class="warning-container mt-4 p-2" color="black">
        <fa-icon [icon]="warningIcon" class="pe-2" title="Warning Sign"></fa-icon>
        <span>This system is for authorized users only. We may monitor, record, and disclose all activity and information on this system, subject to local law.</span>
      </div>
    </div>
  </div>
</div>


