import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { FactorselectionComponent } from './factorselection/factorselection.component';
import { FidoComponent } from './fido/fido.component';
import { InitComponent } from './init/init.component';
import { LdapComponent } from './ldap/ldap.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PushComponent } from './push/push.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { HomeComponent } from "./home/home.component";
import { SecuridComponent } from "./securid/securid.component";
import { DeniedComponent } from "./denied/denied.component";
import { HelpPageComponent } from "./help-page/help-page.component";
import { CookiesPolicyComponent } from "./cookies-policy/cookies-policy.component";
import { InfoPageComponent } from "./info-page/info-page.component";
import { LogoutPageComponent } from './logout-page/logout-page.component';
import {NeedRestartComponent} from "./need-restart/need-restart.component";
import {factorFlowGuard, authGuard, initFlowGuard} from "./route-guards/activate-guards";
import {IdpComponent} from "./idp/idp.component";
import {IdpCompleteComponent} from "./idp-complete/idp-complete.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import { SmsTosComponent } from "./sms-tos/sms-tos.component";
import {EmeaCookiesComponent} from "./emea-cookies/emea-cookies.component";
import {MotpComponent} from "./motp/motp.component";
import {SotpComponent} from "./sotp/sotp.component";
import {EmailotpComponent} from "./emailotp/emailotp.component";
import {VoiceotpComponent} from "./voiceotp/voiceotp.component";
import {RegisterCredentialsComponent} from "./register-credentials/register-credentials.component";

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [initFlowGuard] },
  { path: 'home', component: HomeComponent, canActivate: [initFlowGuard] },
  { path: 'fido', component: FidoComponent, canActivate: [factorFlowGuard] },
  { path: 'changePassword', component: ChangePasswordComponent, canActivate: [factorFlowGuard]  },
  { path: 'security-key', component: FidoComponent, canActivate: [factorFlowGuard] },
  { path: 'verify', component: LdapComponent, canActivate: [factorFlowGuard] },
  { path: 'sotp', component: SotpComponent, canActivate: [factorFlowGuard] },
  { path: 'eotp', component: EmailotpComponent, canActivate: [factorFlowGuard] },
  { path: 'votp', component: VoiceotpComponent, canActivate: [factorFlowGuard] },
  { path: 'motp', component: MotpComponent, canActivate: [factorFlowGuard] },
  { path: 'welcome', component: WelcomeComponent, canActivate: [authGuard] },
  { path: 'oauth2', component: InitComponent, canActivate: [initFlowGuard] },
  { path: 'direct', component: InitComponent, canActivate: [initFlowGuard] },
  { path: 'factorselection', component: FactorselectionComponent, canActivate: [factorFlowGuard] },
  { path: 'push', component: PushComponent, canActivate: [factorFlowGuard] },
  { path: 'denied', component: DeniedComponent },
  { path: 'self-service', component: SelfServiceComponent, canActivate: [authGuard] },
  { path: 'securid', component: SecuridComponent, canActivate: [factorFlowGuard] },
  { path: 'help', component: HelpPageComponent },
  { path: 'cookies', component: CookiesPolicyComponent },
  { path: 'aboutAuthHub', component: InfoPageComponent },
  { path: 'signed-out', component: LogoutPageComponent },
  { path: 'idp', component: IdpComponent, canActivate: [factorFlowGuard]},
  { path: 'idpComplete', component: IdpCompleteComponent},
  { path: 'restartRequired', component: NeedRestartComponent },
  { path: "sms-terms-of-service", component: SmsTosComponent },
  { path: "emea-cookies", component: EmeaCookiesComponent },
  { path: "register", canActivate: [authGuard], children: [
      { path: 'sms', component: SotpComponent },
      { path: 'email', component: EmailotpComponent },
      { path: 'totp', component: MotpComponent },
      { path: 'fido', component: FidoComponent },
      { path: 'securitykey', component: FidoComponent },
      { path: 'totp_push', component: PushComponent },
      { path: '', component: RegisterCredentialsComponent }
    ] },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
