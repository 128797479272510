<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Identity Provider
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span *ngIf="!errorDiv">Connecting to {{idpService.idpFriendlyName? idpService.idpFriendlyName : "Identity Provider"}}</span>
        </div>

        <div class="my-4">
          <mat-progress-bar *ngIf="!errorDiv" class="position-relative" mode='indeterminate'></mat-progress-bar>
        </div>
        <div class="row mt-3" *ngIf="factorSelection || errorDiv">
          <div class="col">
            <button class="secondary-btn" (mousedown)="chooseAnother()">More Options</button>
          </div>
        </div>
        <div>
          <app-userinfo></app-userinfo>
        </div>

      </div>
    </div>
  </div>
</div>
