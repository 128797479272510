<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Mobile OTP Verification
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <div *ngIf="multiFactor" class="h-100">
          <app-multi-credentials class="h-100" [errorMsg]="errorMsg" [credentialOptions]='mobileOtpOptions' buttonLabel='Continue'
                                 (selectedCredential)="selectedCredential($event)"></app-multi-credentials>
        </div>

        <form *ngIf="!multiFactor" [formGroup]="verifyMobileOtpForm" novalidate
              [style.visibility]="ready? 'visible' : 'hidden'">

          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>

          <div *ngIf="isRegisterFlow">
            <div>
              Scan the QR code with your mobile authenticator & enter security code to register {{selectedCredValue}}
            </div>
            <div class="row text-center">
              <qrcode [qrdata]="qrData" [width]="256"></qrcode>
            </div>
          </div>

          <div *ngIf="!isRegisterFlow">
            <span >
              Enter Mobile OTP from device {{selectedCredValue}}
            </span>
          </div>

          <div class="row">
            <app-otp-input (otpEntryChange)="onOtpEntryChange($event)" [ready]="ready"></app-otp-input>
          </div>

          <div class="row">
            <div class="col ms-auto">
              <button class="secondary-btn" (mousedown)="factorSelect()" *ngIf="factorSelection">
                More Options
              </button>
            </div>
          </div>

          <div>
            <app-userinfo></app-userinfo>
            <app-loading *ngIf="!ready && !errorDiv"></app-loading>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
