import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { decode, encode } from '../shared/base64';
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import { FidoService } from "../service/fido.service";
import {Subscription} from "rxjs";
import {FidoregService} from "../service/fidoreg.service";
import {GlobalService} from "../service/global.service";

@Component({
    selector: 'app-fido',
    templateUrl: './fido.component.html'
})
export class FidoComponent implements OnInit, OnDestroy {

  manualLoginRequired = false;
  loginForm: UntypedFormGroup;
  data = null;
  errorDiv: any;
  errorMsg: any;
  fidoFactors: boolean = false;
  factorSelection: boolean = false;
  ready: boolean = true;
  pageSubs: Subscription[] = new Array<Subscription>();
  isRegisterFlow: boolean;
  credType: string;

  constructor(private factorselectionService: FactorselectionService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              public fidoService: FidoService,
              public fidoregService: FidoregService,
              public globalService: GlobalService,
              private displayService: DisplayService) { }

  ngOnInit() {
      if (this.displayService.userName == null) {
          this.router.navigate(["../"]);
      }
      this.doInit();
  }

  doInit() {
      this.manualLoginRequired = false;
      this.loginForm = this.formBuilder.group({
          userName: ['', Validators.required],
      });

    this.isRegisterFlow = location.href.endsWith("register/fido") || location.href.endsWith("register/securitykey");
    this.credType = this.displayService.fidoCredType;

    if (this.isRegisterFlow) {
      this.initRegisterFlow();
    } else {
      this.initFactorFlow();
    }
  }

  private initFactorFlow() {
    // Set variable for factor flow
    this.factorSelection = this.displayService.factorSelection;
    this.fidoFactors = this.displayService.fidoFactors;
    this.generateFidoAuthChallenge();
  }


  private initRegisterFlow() {
    // Init register flow variables
    this.factorSelection = true;
    this.data = this.router.lastSuccessfulNavigation?.extras?.state?.data;

    // Verify FIDO registration challenge
    this.onVerifyFido();
  }

  private generateFidoAuthChallenge() {
    this.ready = false;
    this.pageSubs.push(
      this.fidoService.authenticationGenerateChallenge(this.displayService.userName).subscribe({
        next: (data) => {
          this.ready = true;
          if ((this.credType === "FIDO" && data["nextaction"] === "FIDO_AUTH_VERIFY_CHALLENGE") || (this.credType === "SECURITYKEY" && data["nextaction"] === "SECURITYKEY_AUTH_VERIFY_CHALLENGE")) {
            this.data = data;
            this.displayService.flowState = data["flowState"];
            // if (!this.fidoService.isInteractionButtonToBeDisplayed() || this.globalService.isRememberDeviceEnabled()) {
            if (!this.fidoService.isInteractionButtonToBeDisplayed() || this.globalService.isRememberDeviceEnabled()) {
              /**
               * Disable auto login prompt as few browsers need user-gesture like a 'click' action to be made.
               */
              this.onVerifyFido()
            } else {
              this.manualLoginRequired = true;
              this.ready = true;
            }
          } else {
            this.displayService.errorMsg = "Error in data from server";
            this.errorMsg = this.displayService.errorMsg;
            this.errorDiv = true;
          }
        },
        error: (err) => {
          this.ready = true;
          this.errorDiv = this.displayService.errorDiv;
          this.errorMsg = this.displayService.errorMsg;
        }
      })
    );
  }

  onVerifyFido() {
    // Unset manual login after first attempt
    this.manualLoginRequired = false;

    // Wait for credential from browser
    this.getNavCred().then((response) => {
      const makeCredResponse = this.publicKeyCredentialToJSON(response);
      // Verify FIDO authentication
      this.pageSubs.push(
        this.fidoVerify(makeCredResponse)
      )
    }).catch((err) => {
      this.ready = true;
      this.errorMsg = "Error verifying your credential";
      this.errorDiv = true;
    });
  }

  /**
   * Use data from initial FIDO challenge call for verification call
   * @private
   */
  private getNavCred() {
    try {
      if (this.isRegisterFlow) {
        return this.fidoregService.createNavCredFromData(this.data);
      } else {
        return this.fidoService.getNavCredFromData(this.data);
      }
    } catch (error) {
      this.errorMsg = "Error processing data from server";
      this.errorDiv = true;
      this.ready = true;
    }

  }

  private fidoVerify(response) {
    this.ready = false;
    if (this.isRegisterFlow) {
      return this.fidoregService.sendRegistrationVerifyChallenge(response, this.credType).subscribe({
        next: (data) => {
          this.router.navigateByUrl("self-service", {state: {newCredAdded: true}});
        },
        error: (err) => {
          this.errorMsg = this.displayService.errorMsg;
          this.errorDiv = true;
          this.ready = true;
        }
      })
    } else {
      return this.fidoService.sendLoginVerifychallenge(response).subscribe({
        next: (response) => {
          this.displayService.routeActions(response);
        },
        error: (err) => {
          this.ready = true;
          this.errorDiv = this.displayService.errorDiv;
          this.errorMsg = this.displayService.errorMsg;
        }
      });
    }
  }

  publicKeyCredentialToJSON = (pubKeyCred) => {
    if (pubKeyCred instanceof Array) {
      let arr = [];
      for (let i of pubKeyCred)
          arr.push(this.publicKeyCredentialToJSON(i));
      return arr
    }
    if (pubKeyCred instanceof ArrayBuffer) {
      encode(pubKeyCred);
      return encode(pubKeyCred)
    }
    if (pubKeyCred instanceof Object) {
      let obj = {};
      for (let key in pubKeyCred) {
          obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key])
      }
      return obj
    }
    return pubKeyCred
  }

  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
        sub?.unsubscribe();
    });
  }

}
