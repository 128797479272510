import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';
import {decode} from "../shared/base64";


@Injectable({
  providedIn: 'root'
})
export class FidoService {

  constructor(private http: HttpClient,
              private displayService: DisplayService) { }

  authenticationGenerateChallenge(userName) {
    const postData = {
      documentDomain: location.origin,
      fidoCredType: this.displayService.fidoCredType,
      deviceTag: true
    };

    if (userName) {
      postData["userName"] = userName;
    }
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/FIDOAuthChallengeGenerator', postData);
  }

  public sendLoginVerifychallenge(makeCredReq) {
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/FIDOAuthChallengeVerifier', makeCredReq);
  }

  isInteractionButtonToBeDisplayed() {
    /**
     * If the browser is Safari, then the button should be displayed always.
     */
    return navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") <= -1;
  }

  getNavCredFromData(factorData) {
    if (sessionStorage.getItem("__authn_debug000")) {
      let option = sessionStorage.getItem("__authn_debug000");
      if (("" + option) === ("" + window["RSWF"])) {
        factorData["data"]["allowCredentials"].forEach((r) => {
          r["transports"] = ["internal"];
        });
      } else if (("" + option) === ("" + window["RFWS"])) {
        factorData["data"]["allowCredentials"].forEach((r) => {
          r["transports"] = ["usb", "ble", "nfc"];
        });
      }
    }

    factorData["data"]["challenge"] = decode(factorData["data"]["challenge"]);
    for (let allowCred of factorData["data"]["allowCredentials"]) {
      allowCred["id"] = decode(allowCred["id"]);
    }

    return navigator.credentials.get({ publicKey: factorData["data"] });
  }

}
