import { Injectable } from '@angular/core';
import * as Fingerprint2 from "fingerprintjs2";
import {of, Subject} from "rxjs";

declare function encode_deviceprint(): any;

@Injectable({
  providedIn: 'root'
})
export class DeviceprintService {
  riskTypeList: any = ['IARISK', 'AARISK', 'RSARISK']
  riskType: string = "RSARISK";
  fingerPrintSource: Subject<string> = new Subject<string>();
  fingerPrintDna: string;

  constructor() { }

  /**
   * Updates the risk type in multiple places. Does nothing if the parameter is not one of the expected risk types.
   * @private
   */
  public setRiskType(newRiskType: string) {
    // Update risk type if valid
    if (this.riskTypeList.find(rType => rType === newRiskType)) {
      this.riskType = newRiskType;
    } else {
      console.log(`Invalid risk type ${newRiskType}. Using default.`);
    }
    // Propagate risk type
    localStorage.setItem("riskType", this.riskType);

    // Generate device fingerprint for risk type
    this.setDevicePrint();
  }

  /**
   * Set the device print according to the risk type
   */
  setDevicePrint(): any {
    // IA and RSA risk use unique fingerprints
    if (this.riskType === "IARISK") {
      this.fingerPrintDna = window["IaDfp"] && window["IaDfp"].readFingerprint();
    } else if (this.riskType === "RSARISK") {
      this.fingerPrintDna = encode_deviceprint();
    } else {
      // Default fingerprint occurs on delay
      this.setDefaultFingerprint();
    }
  }

  /**
   * Default fingerprint uses FingerprintJS v2. Delay processing for short period, to ensure consistency.
   * @Doc https://github.com/fingerprintjs/fingerprintjs/tree/v2
   *
   * @private
   */
  private setDefaultFingerprint() {
    const self = this;
    return setTimeout(function () {
      const options = {};
      Fingerprint2.getPromise(options).then(function (components) {
        const object = {};
        for (const index in components) {
          const obj = components[index];
          object[obj.key] = obj.value;
        }
        self.fingerPrintDna = JSON.parse(JSON.stringify(object));
        self.fingerPrintSource.next(self.fingerPrintDna);
      });
    }, 500);
  }

  /**
   * Method to get fingerprint, ensuring it is
   */
  getFingerprintComplete() {
    if (this.fingerPrintDna) {
      return of(this.fingerPrintDna);
    } else {
      return this.fingerPrintSource;
    }
  }
}

