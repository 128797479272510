<h2 mat-dialog-title class="dialog-header">Login Assistance Information</h2>
<mat-dialog-content class="container">
  <div class="row">
    <div class="col">
      If you need assistance to log in, copy the following information and send it to the help desk:
    </div>
  </div>
  <div class="row" *ngIf="lastTransaction">
    <div class="col">
      <strong>Timestamp:</strong> {{lastTransaction}}
    </div>
  </div>
  <div class="row" *ngIf="clientTxId">
    <div class="col">
      <strong>Client Transaction:</strong> {{clientTxId}}
    </div>
  </div>
  <div class="row" *ngIf="userName">
    <div class="col">
      <strong>User:</strong> {{userName}}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container gx-0">
    <div class="row g-2">
      <div class="col-6 ps-0">
        <button mat-button name="Submit Button" class="primary-btn" cdkFocusInitial [disabled]="btnClipboardDisabled" (click)="onCopy()">Copy to Clipboard</button>
      </div>
      <div class="col-6 pe-0">
        <button mat-button name="Close Button" class="secondary-btn" (click)="onClose()">Close</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
