import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class MotpService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService
  ) { }

  public verifyMobileOtp(otp, credID, credValue, isRegister?: boolean) {
    const postData = { credId: credID, otp: otp, credValue: credValue};
    if (isRegister) {
      postData["userAccessToken"] = true;
    } else {
      postData["deviceTag"] = true;
    }
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/MobileOTPVerifier', postData);
  }
}
