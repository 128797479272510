<div class="main-console-container">
  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Register New Credential
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <div [ngSwitch]="regState" [style.visibility]="ready ? 'visible' : 'hidden'">

          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMessage"></app-errormsg>
          <app-loading *ngIf="!ready"></app-loading>

          <!-- Step 1: Select credential type -->
          <div class="row" *ngSwitchCase="State.SELECT">
            <h6>
              Select New Credential Type
            </h6>
            <mat-radio-group [(ngModel)]="selectedCredType" name="CredRegistration" id="CredRegistration">
              <div class="col-12 factors-list" *ngFor="let cred of regCredMap | keyvalue;">
                <label>
                  <mat-radio-button disableRipple [value]="cred.value.type">
                    {{cred.value.regName || cred.value.name}}
                  </mat-radio-button>
                </label>
              </div>
            </mat-radio-group>

            <!-- Buttons -->
            <div class="col-12 mt-4">
              <div class="row gy-2">
                <div class="col-md-6">
                  <button class="primary-btn" [disabled]="!selectedCredType" (click)="onSubmitType()">
                    Next
                  </button>
                </div>
                <div class="col-md-6">
                  <button class="secondary-btn" (click)="onBack()">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Step 2: Fill out credential details -->
          <form [formGroup]="formGroup" class="row" *ngSwitchCase="State.NAME">
            <!-- Basic Credential Value Input -->
            <div>
              <h6 [ngSwitch]="!(regCredMap[selectedCredType]?.label)">
                <div *ngSwitchCase="false">
                  Enter {{regCredMap[selectedCredType]?.label}}
                </div>
                <div *ngSwitchCase="true">
                  Enter friendly name for {{regCredMap[selectedCredType].name}}
                </div>
              </h6>


              <input class="regInputField form-control user-input" [formControl]="credValueControl" type="text"
                     (focus)="showError = false">

              <!-- Display legal notice for certain credential registration -->
              <div *ngIf="regCredMap[selectedCredType]?.legalNotice" class="tos-notice-container rounded mt-3 p-2" color="black">
                <fa-icon [icon]="infoIcon" class="me-2" title="Info Sign"></fa-icon>
                <span>{{regCredMap[selectedCredType]?.legalNotice}}</span>
              </div>
            </div>

            <!-- Type-Specific Form Details -->
            <div *ngIf="selectedCredType === 'totp_push'">
              <p class="submission-help-font">Enter your VIP Access Credential ID</p>
              <input class="form-control user-input" [formControl]="vipCredControl" type="text"
                     (focus)="showError = false">
            </div>

            <!-- Buttons -->
            <div class="col-12 mt-4">
              <div class="row gy-2">
                <div class="col-md-6">
                  <button class="primary-btn" [disabled]="formGroup.invalid" (click)="onRegister()">
                    Next
                  </button>
                </div>
                <div class="col-md-6">
                  <button class="secondary-btn" (click)="onBack()">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>
