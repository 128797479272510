<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Factor Selection
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <form class="h-100" novalidate [style.visibility]="ready? 'visible' : 'hidden'" name="factorSelection">
          <div>
            <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <span>Select an authentication method</span>
          </div>

          <mat-radio-group (change)="onChange($event)" name="factorSelection" id="factorSelection">
            <div class="row gx-0 factors-list" *ngFor="let factorName of factorOptions">
              <label>
                <mat-radio-button disableRipple id="{{factorName}}" value={{factorName}}>
                  <span [innerHTML]="labelMap[factorName] || factorName"></span>
                </mat-radio-button>
              </label>
            </div>
          </mat-radio-group>
          <label class="row checkbox-label my-3" for="rememberFactor">
            <mat-checkbox disableRipple id="rememberFactor" name="rememberFactor" [(ngModel)]="enableRememberFactor">
              Remember my choice
              <img src="assets/img/help-icon.svg" title="The selected authentication factor will be saved for future logins with your account." alt="Remember factor"
                   class="tooltip-icon"/>
            </mat-checkbox>
          </label>
          <button id="select-btn" class="primary-btn mb-2" (click)="onSelect()" [disabled]="!factorSelection">
            Select
          </button>

          <div>
            <app-userinfo></app-userinfo>
            <app-loading *ngIf="!ready"></app-loading>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
