<div class="my-3">
  <div class="row g-0 justify-content-around" id="otp-field-container" #otpWrapper>

    <div class="col align-items-center justify-content-center" *ngFor="let digit of allDigitInputs; let index=index;trackBy:trackByIndex; last as isLast;">
          <input #otpField class="otp-input-box text-center w-50 mx-auto" maxlength="1" [type]="hideEntry ? 'password' : 'number'"
                 [(ngModel)]="allDigitInputs[index]"
                 (keydown)="onKeydown($event, index)" (paste)="onPaste($event, index)"/>
    </div>

  </div>

  <div *ngIf="otpErrorMsg" class="alert alert-danger move-up mb-0 mt-1">
    <div class="alert-red">
      {{otpErrorMsg}}
    </div>
  </div>
</div>


