import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';
import {Router} from "@angular/router";
import {GlobalService} from "./global.service";
import {AuthState, AuthStateService} from "./auth-state.service";
import {ResetService} from "./reset.service";
import {IdpService} from "./idp.service";
import {RememberInfoService} from "./rememberInfo.service";

@Injectable({
  providedIn: 'root'
})
export class FactorselectionService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService,
    private rememberService: RememberInfoService,
    private router: Router,
    private authStateService: AuthStateService
  ) { }

  public onSelect(factorName) {
    const postData = { factor: factorName };

    return this.http.post(this.displayService.serviceUrl + 'auth/v1/SelectedFactor', postData);
  }

  /**
   * Returns to a previous state in authentication flow. If in a non-recoverable state (i.e. invalid x-flow-state),
   * restart auth flow entirely. Otherwise use the default back button logic.
   */
  public chooseAnother() {

    if (this.authStateService.getAuthStatus() === AuthState.RESTART_NEEDED) {
      this.router.navigate(['restartRequired']);
    } else if (this.authStateService.isAuthenticated()) {
      // While authenticated, this method is only invoked when registering credential
      this.router.navigate(["self-service"]);
    } else {
      const postData = {
        "action": "restorelastaction",
        "LATEST-FLOW-STATE": "[LATEST-FLOW-STATE]"
      };
      return this.http.post(this.displayService.serviceUrl + 'auth/v1/SelectedFactor', postData).subscribe((data) => {
        const resp = data;
        resp["skipFactorAutoRedirect"] = true;
        this.rememberService.clearCurrentFactor();
        this.displayService.routeActions(resp);
      });
    }

  }

}
