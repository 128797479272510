import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class SelfService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService,
  ) { }

  getCredentials() {
    return this.http.get(this.displayService.serviceUrl + 'factor/v1/MeCreds');
  }

  statusUpdate(credId: string, statusValue: string, isDefault: boolean) {
    const body = {
      default: isDefault,
      status: statusValue
    };
    return this.http.put(this.displayService.serviceUrl + 'factor/v1/MeCreds/' + credId, body);
  }

  setDefaultFalse(items: { credId: string; statusValue: string }[]) {
    let response = new Subject<any>();
    const observables = [];
    items.map((item) => {
      const body = {
        default: false,
        status: item.statusValue
      };
      observables.push(this.http.put(this.displayService.serviceUrl + 'factor/v1/MeCreds/' + item.credId, body));
    });
    if (observables.length === 0) {
      setTimeout(() => {
        response.next(true);
      }, 10);
    } else {
      forkJoin(observables).subscribe((result) => {
        response.next(true);
      });
    }
    return response;
  }

  deleteCredential(credId: string) {
    return this.http.delete(this.displayService.serviceUrl + 'factor/v1/MeCreds/' + credId);
  }

  registerOtp(credentialType: string, value: string) {
    const body = {
      credType: credentialType,
      credValue: value,
      language: this.displayService.getBrowserLang(),
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/OTPRegistrar', body);
  }

  otpVerifier(credId: string, otp: string) {
    const body = {
      credId: credId,
      otp: otp,
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/OTPVerifier', body);
  }

  public registerMobileOtp(credentialType: string, value: string) {
    const body = {
      credType: credentialType,
      credValue: value,
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/MobileOTPRegistrar', body);
  }

  public verifyMobileOtp(credId: string, credValue: string, otp: string) {
    const body = {
      credId: credId,
      credValue: credValue,
      otp: otp,
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/MobileOTPVerifier', body);
  }

}
