<div class="main-console-container">

  <div class="row">
    <!-- Title -->
    <div class="col-md-6">
      <div class="main-console-title">
        Cookie Policy Information
      </div>
    </div>

    <!-- Regional Choice -->
    <div class="col-12 col-md-6">
      <div class="main-console-card">
        <h5>Collection and Use of Your Information (including via Cookies)</h5>
        <span>
          Please review the Cookie Policy for your region or country of residence:
        </span>
        <ul>
          <li>
            <a class="reference" routerLink="/emea-cookies">EMEA Region</a>
          </li>

          <li>
            <a class="reference" href="https://www.bnymellon.com/us/en/data-privacy.html">All Other Regions</a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>
