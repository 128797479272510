<div class="main-console-container" >

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        <span *ngIf="!isRegisterFlow">FIDO Device Authentication</span>
        <span *ngIf="isRegisterFlow">FIDO Device Registration</span>
      </div>
    </div>


    <div class="col-md-6">
      <div class="main-console-card">
        <form [formGroup]="loginForm" novalidate class="h-100" [style.visibility]="ready? 'visible' : 'hidden'">

          <div>
            <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
            <div class="row">
              <!-- Some browsers require user interaction to initiate -->
              <div class="col-12" *ngIf="manualLoginRequired">
                <div class="col-12">
                  User input required to begin
                </div>
                <i class="col-12" *ngIf="!errorDiv">
                  Click 'Next' to continue
                </i>
              </div>
              <!-- Validation -->
              <div class="col-12" *ngIf="!manualLoginRequired">
                <div class="col-12" *ngIf="credType==='FIDO'">
                  Validate your biometric
                </div>
                <div class="col" *ngIf="credType==='SECURITYKEY'">
                  Validate your security key
                </div>

                <i class="col-12" *ngIf="!errorDiv">
                  It may take a few moments for the pop-up to appear
                </i>
              </div>
            </div>
          </div>


          <div class="key" *ngIf="!manualLoginRequired">
            <img *ngIf="credType==='FIDO'" src="assets/img/biometric.svg" style="height:70px;padding:20px;" alt=""/>
            <img *ngIf="credType==='SECURITYKEY'" src="assets/img/security-key.svg" style="height:70px;padding:20px;" alt=""/>
          </div>

          <div class="row mt-3">
            <div class="col" *ngIf="manualLoginRequired" (click)="onVerifyFido()">
              <button class="primary-btn" [disabled]="!ready">
                Next
              </button>
            </div>
            <div class="col ms-auto" *ngIf="factorSelection">
              <button class="secondary-btn " (mousedown)="factorSelect()">
                More Options
              </button>
            </div>
          </div>

          <div>
            <app-userinfo></app-userinfo>
            <app-loading *ngIf="!ready && !errorDiv"></app-loading>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
