import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import { Subscription } from "rxjs";
import {MatRadioChange} from "@angular/material/radio";
import {IdpService} from "../service/idp.service";
import {RememberInfoService} from "../service/rememberInfo.service";

@Component({
  selector: 'app-factorselection',
  templateUrl: './factorselection.component.html'
})
export class FactorselectionComponent implements OnInit, OnDestroy {

  factorOptions: string[] = [];
  errorDiv: boolean = false;
  errorMsg: String;
  @Input()
  factorSelection: string;
  ready: boolean = true;
  labelMap = {
    "PUSH": "Send a <strong>push request</strong>",
    "MOBILEOTP": "Use code from <strong>mobile app</strong>",
    "OTP": "Send code via <strong>(SMS/Email)</strong>",
    "FIDO": "Use <strong>your device</strong>",
    "SECURITYKEY": "Use a <strong>security key</strong>",
    "PASSWORD": "Enter <strong>password</strong>",
    "EMAILOTP": "<strong>Email</strong> a security code",
    "SMSOTP": "<strong>Text</strong> a security code",
    "IVROTP": "<strong>Call phone</strong> with a security code"
  };

  // Add labels for each custom authentication flow here
  customAuthLabelMap = {
    "securid": "RSA Authenticator",
    "rsaaa": "RSA Adaptive Authentication",
    "IDP": "Identity Provider"
  }
  enableRememberFactor:boolean = false;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private displayService: DisplayService,
              private router: Router,
              private factorselectionService: FactorselectionService,
              private rememberService: RememberInfoService,
              private idpService: IdpService
  ) { }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }

    if (this.displayService.isRememberedFactorAvailable()) {
      this.factorSelection = this.rememberService.getCurrFactor();
      this.onSelect();
    } else {
      // Initialize factor selection form
      this.factorOptions = this.displayService.factorOptions;
      this.enableRememberFactor = this.rememberService.rememberFactors;
      this.setFactorLabels();
    }
  }

  setFactorLabels() {
    let customFactorOptions = this.displayService.customFactorOptions;

    // Map factor labels based on factor type
    this.factorOptions.forEach((factorName, index) => {
      let factorType = factorName.split('/')[0];
      if (factorType === "CUSTOM") {
        // Use flowId mapping for custom factor labels
        let flowId = customFactorOptions[factorName];
        this.labelMap[factorName] = this.customAuthLabelMap[flowId];
      } else if(factorType === "IDP") {
        // Use predicate for IDP labels
        let idpName = customFactorOptions[factorName];
        if (!idpName) {
          // If no predicate is found, just name generically. The idp name should never be null
          idpName = this.customAuthLabelMap[factorType];
        } else if (idpName.endsWith("Internal")) {
          // If predicate contains "Internal" or "- Internal" suffix, strip it from idp name
          idpName = idpName.replace(/(- )*(Internal)/, "");
        }
        this.customAuthLabelMap[factorName] = idpName;
        this.labelMap[factorName] = `Login with <strong>${idpName}</strong>`;
      } else if (this.labelMap[factorType]) {
        // Use label for basic factor types (e.g. PASSWORD/XYZ is mapped to PASSWORD)
        this.labelMap[factorName] = this.labelMap[factorType]
      } else if (this.customAuthLabelMap[factorType]) {
        // Use custom label map if nothing else present
        this.labelMap[factorName] = this.customAuthLabelMap[factorType];
      }

      // Delete unlabeled factor options
      if (!this.labelMap[factorName]) {
        delete this.factorOptions[index];
      }
    });

    // Filter array for valid results, since deleting a factor only sets the element value to undefined
    this.factorOptions = this.factorOptions.filter(item => item)
  }

  onChange(buttonSelection: MatRadioChange) {
    this.factorSelection = buttonSelection.value;
    let factorDescriptor =  this.displayService?.customFactorOptions?.[this.factorSelection];
    if (factorDescriptor) {
      console.log("%s : %s", factorDescriptor, this.factorSelection);
    } else {
      console.log(this.factorSelection);
    }
  }

  onSelect() {
    this.ready = false;
    this.errorDiv = false;
    this.errorMsg = "";

    this.pageSubs = [this.factorselectionService.onSelect(this.factorSelection)
      .subscribe({
        next: (data) => {
          this.factorOptions = [];
          this.rememberService.rememberFactors = this.enableRememberFactor;
          if (this.enableRememberFactor) {
            this.rememberService.setCurrFactor(this.factorSelection);
          }
          if (this.factorSelection.startsWith("IDP")) {
            this.idpService.idpFriendlyName = this.customAuthLabelMap[this.factorSelection];
          }
          if (data["nextaction"] === "FACTOR_SELECTION") {
            this.factorOptions = data["currentFactors"];
            this.ready = true;
          } else {
            this.displayService.routeActions(data).then((navToNextAction) => {
              if (!navToNextAction) {
                this.errorMsg = data['nextaction'] + " is not a recognized action. Please select a different factor"
                this.errorDiv = true;
                this.factorOptions = this.displayService.factorOptions;
                this.ready = true;
              }
            })
          }
        },
        error: (err) => {
          this.setFactorLabels();
          this.errorDiv = this.displayService.errorDiv;
          this.errorMsg = this.displayService.errorMsg;
          this.ready = true;
        }
      })
    ];
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
