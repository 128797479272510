import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { DisplayService } from '../service/display.service';
import { SelfService } from '../service/self.service';
import {faTrashAlt, faHomeAlt, faSyncAlt, faPlus, faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from "rxjs";
import {CredType, CredTypeMap} from "../shared/credentials";

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
})
export class SelfServiceComponent implements OnInit, OnDestroy {
  credentialsList = [];
  statuses = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
  ];
  statusMap = {};

  credTypeMap: { [id: string] : CredType };

  private months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  errorMessage: string;
  showError: boolean = false;
  isLoading: boolean = true;
  deleteIcon = faTrashAlt;
  refreshIcon = faSyncAlt;
  homeIcon = faHomeAlt;
  plusIcon = faPlus;
  checkIcon = faCircleCheck;
  pageSubs: Subscription[] = new Array<Subscription>();
  statusMsg: string;

  constructor(
    private selfService: SelfService,
    private router: Router,
    public displayService: DisplayService,
    private confirmDialog: MatDialog
  ) {
    this.credTypeMap = CredTypeMap;
  }

  ngOnInit(): void {
    this.statuses.forEach((status) => {
      this.statusMap[status.value] = "Y";
    });
    let successMsg;
    let state = this.router.lastSuccessfulNavigation?.extras?.state;
    if (state?.newCredAdded) {
      if (state?.newCredName) {
        successMsg = "Successfully added new credential " + state?.newCredName;
      } else {
        successMsg = "Successfully added new credential";
      }
    }
    this.getCreds(successMsg);
  }

  getCreds(successMsg?: string) {
    this.pageSubs.push(
      this.selfService.getCredentials().subscribe({
        next: (res: any) => {
          let finalArray = res;
          finalArray.sort((c1, c2) => {
            let rank1 = (this.credTypeMap[c1.credType] || {}).rank;
            let rank2 = (this.credTypeMap[c2.credType] || {}).rank;
            if (rank1 < rank2) {
              return -1;
            } else if (rank1 > rank2) {
              return 1;
            } else {
              return 0;
            }
          });
          this.credentialsList = finalArray;
          this.statusMsg = successMsg;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.statusMsg = "";
          this.showErrorMessage(err.error);
        }
      })
    );
  }

  getCustomDateFormat(dateStr) {
    let dt;
    try {
      dt = new Date(dateStr);
      if (isNaN(dt.getTime()) || !dt.getTime()) {
        throw new Error("Invalid Date String");
      }
      let res = "";
      res += this.months[dt.getMonth()] + " " + dt.getDate() + ", " + dt.getFullYear();
      return res;
    } catch (exjs) {
      return dateStr;
    }
  }

  onStatusChange(credItem, status) {
    this.updateCredStatus(credItem.credId, status, credItem.default);
  }

  updateCredStatus(id, value, isDefault) {
    this.pageSubs.push(
      this.selfService.statusUpdate(id, value, isDefault).subscribe({
        next: (res) => {
          this.getCreds();
        },
        error: (err) => {
          const index = this.credentialsList.findIndex((item) => item.credId === id);
          if (index !== -1) {
            // For reseting Status dropdown
            const statusCopy = this.credentialsList[index].status;
            this.credentialsList[index].status = null;
            setTimeout(() => {
              this.credentialsList[index].status = statusCopy;
            }, 1);
          }
          this.showErrorMessage(err.error);
        }
      })
    );
  }

  handleDeleteCred(creditem) {
    let credTypeLabel = this.credTypeMap[creditem.credType].name;
    let dc: MatDialogConfig = {
      data: {
        message: `Do you want to delete the credential of type ${credTypeLabel}?`,
        btnType: "Yes"
      }
    }
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, dc);
    this.pageSubs.push(
      dialogRef.afterClosed().subscribe( result => {
        if (result?.response == dc.data.btnType) {
          this.deleteCred(creditem);
        }
      })
    );
  }

  private deleteCred(credItem) {
    this.selfService.deleteCredential(credItem.credId).subscribe({
      next: (res) => {
        this.getCreds("Successfully removed credential");
      },
      error: (err) => {
        this.isLoading = false;
        this.showErrorMessage(err.error);
      }
    })
  }

  handleDeleteAll() {
    let dc: MatDialogConfig = {
      data: {
        message: `All your credentials will be deleted. Are you sure?`,
        btnType: "Yes"
      }
    }
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, dc);
    dialogRef.afterClosed().subscribe( result => {
      if (result?.response == dc.data.btnType) {
        this.deleteAll();
      }
    });
  }

  private deleteAll() {
    let credCount = this.credentialsList.length;
    let ctr = 0;
    this.credentialsList.map((item) => {
      this.selfService.deleteCredential(item.credId).subscribe({
        next: (res) => {
          ctr++;
          if (ctr === credCount) {
            this.getCreds("Successfully removed credentials");
          }
        },
        error: (err) => {
          this.showErrorMessage(err.error);
          this.getCreds();
        }
      })
    })
  }

  showErrorMessage(error) {
    let errorObject = error || {};
    this.errorMessage = errorObject.errorMessage || "Unable to register, please try again.";
    this.showError = true;
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
