<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Page Not Found
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <p>The URL entered does exist. The page you are looking for may have been moved or renamed.</p>

        <a class="reference" routerLink="/">
          Return to the homepage
        </a>
      </div>
    </div>
  </div>
</div>
