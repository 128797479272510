import {Component, OnInit} from '@angular/core';
import {RememberInfoService} from "../service/rememberInfo.service";
import {DisplayService} from "../service/display.service";

@Component({
  selector: 'app-denied',
  templateUrl: './denied.component.html'
})
export class DeniedComponent implements OnInit {

  constructor(private displayService: DisplayService,
              private rememberInfoService: RememberInfoService) {}

  ngOnInit(): void {
    this.clearRememberDenied();
  }

  private clearRememberDenied() {
    // If remembered user is denied, clear remember me
    if (this.displayService.userName === this.rememberInfoService.getUsername()) {
      this.rememberInfoService.clearRememberMe();
    }
  }
}
