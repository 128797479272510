<div class="main-console-container">
  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        A Better Login Experience
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <p>Single Sign On for BNY Applications.</p>
        <p>Sign-in faster and more securely. No password needed.</p>

        <form novalidate>
          <button id="login-btn" type="login" class="primary-btn align-bottom container-fluid" routerLink="/direct">
            <span>Login with Authentication Hub</span>
          </button>
        </form>

        <div class="text-center text-muted mt-2">
          <i>
            For more details visit our <a class="reference" routerLink="/aboutAuthHub">information page</a>.
          </i>
        </div>
      </div>
    </div>
  </div>
</div>
