import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DisplayService} from "./display.service";
import {tap} from "rxjs/operators";
import {FidoregService} from "./fidoreg.service";
import {PushService} from "./push.service";


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(
    private http: HttpClient,
    private fidoRegService: FidoregService,
    private pushService: PushService,
    private displayService: DisplayService
  ) {  }

  registerCredential(type: string, value: string, vipCredId: string) {
    if (type === "sms" || type === "email") {
      return this.registerOtp(type, value);
    } else if (type === "totp") {
      return this.registerMobileOtp(type, value);
    } else if (type === "fido" || type === "securitykey") {
      return this.registerFido(type, value);
    } else if (type === "totp_push") {
      return this.registerVip(type, value, vipCredId);
    }
  }

  private registerOtp(credentialType: string, value: string) {
    const body = {
      credType: credentialType,
      credValue: value,
      language: this.displayService.getBrowserLang(),
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/OTPRegistrar', body);
  }

  registerMobileOtp(credentialType: string, credValue: string) {
    const body = {
      credType: credentialType,
      credValue: credValue,
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/MobileOTPRegistrar', body);
  }

  public registerFido(type, credVal) {
    return this.fidoRegService.activationGenerateChallenge(credVal,type);
  }

  private registerVip(type: string, value: string, vipCredId: string) {
    return this.pushService.registerVipPush(type, value, vipCredId);
  }
}
