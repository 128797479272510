<head>
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
</head>

<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        Identity Provider
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span *ngIf="!errorDiv">Reading Authentication Response</span>
        </div>
        <div class="my-4">
          <mat-progress-bar *ngIf="!errorDiv" mode='indeterminate'></mat-progress-bar>
        </div>
        <div>
          <app-userinfo></app-userinfo>
        </div>
      </div>
    </div>
  </div>
</div>
