<div class="main-console-container">

  <div class="row gy-md-2">
    <!-- Title -->
    <div class="col-12">
      <div class="main-console-title">
        EMEA Cookie Policy Information
      </div>
    </div>

    <!-- Regional Choice -->
    <div class="col-12">
      <!-- EMEA Card - info generated by OneTrust -->
      <div class="main-console-card">
        <iframe id="iframe-ot-notice" src="./assets/emea-region-iframe.html" class="w-100" scrolling="no">
        </iframe>
        <div>
          <app-loading *ngIf="loading"></app-loading>
        </div>
      </div>
    </div>

  </div>
</div>
