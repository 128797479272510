<div class="main-console-container">

  <div class="row">
    <div class="col-md-6">
      <div class="main-console-title">
        SMS One Time Passcode Terms of Service
      </div>
    </div>

    <div class="col-md-6">
      <div class="main-console-card">
      <span>
        <h5>SMS Terms of Service</h5>
        Please review our Terms of Service regarding SMS One Time Passcodes:
        <ul>
          <li>By using the SMS OTP authentication mechanism, you opt-in to receiving One Time Passcodes via SMS messages</li>
          <li>One message per login</li>
          <li>Message and data rates may apply</li>
          <li>Reply HELP for help</li>
          <li>Reply STOP to opt out</li>
          <li><a class="reference" href="https://www.bnymellon.com/us/en/data-privacy.html">Data Privacy Statement</a></li>
        </ul>
      </span>
      </div>
    </div>
  </div>
</div>
