import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DisplayService} from "../service/display.service";
import {FactorselectionService} from "../service/factorselection.service";
import {Subscription} from "rxjs";
import {IdpService} from "../service/idp.service";

@Component({
  selector: 'app-idp',
  templateUrl: './idp.component.html'
})
export class IdpComponent implements OnInit, OnDestroy{
  errorDiv: boolean;
  errorMsg: string;
  factorSelection: boolean;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private http: HttpClient,
              private displayService: DisplayService,
              private factorselectionService: FactorselectionService,
              public idpService: IdpService) {
  }

  ngOnInit(): void {
    this.factorSelection = this.displayService.factorSelection;
    this.callIdp();
  }

  callIdp() {
    this.pageSubs.push(
      // Generate SAML request for IDP
      this.idpService.generateIdpSaml().subscribe({
        next: (data) => {
          this.addScriptToPage(data);
          this.redirectToIdp();
        },
        error: (err) => {
          console.log(err);
          this.errorDiv = true;
          this.errorMsg = this.displayService.errorMsg;
        }
      })
    );
  }

  chooseAnother() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.factorselectionService.chooseAnother();
  }

  redirectToIdp() {
    // Submit form to initiate SAML flow
    document.forms[0].submit()
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private addScriptToPage(data) {
    // Append HTML response to page
    let idpForm = this.extractContent(data).getElementsByTagName("body")[0];
    document.getElementsByTagName("body")[0].appendChild(idpForm);
  }

  extractContent(html) {
    return new DOMParser()
      .parseFromString(html, "text/html")
      .documentElement;
  }
}
