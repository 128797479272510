<div class="main-console-container">
  <div class="row gy-md-2">
    <div class="col-12">
      <div class="main-console-title">
        Manage Credentials
      </div>
    </div>

    <div class="col-12">
    <div class="main-console-card">
      <app-loading *ngIf="isLoading"></app-loading>

      <div [style.visibility]="isLoading ? 'hidden' : 'visible'">
        <div class="col mb-2" *ngIf="statusMsg && !showError">
          <div class="cred-status p-2 mb-4">
            <fa-icon [icon]="checkIcon" class="pe-2" title="Checkmark Icon"></fa-icon>
            <span>{{statusMsg}}</span>
          </div>
        </div>
        <div class="row px-0 gy-2 justify-content-between mb-3">
          <div class="col-md-3">
            <button class="cred-row-btn" (click)="getCreds()">
              <fa-icon [icon]="refreshIcon" class="pe-2 text-white" title="Refresh Icon"></fa-icon>
              Refresh
            </button>
          </div>
          <div class="col-md-3" *ngIf="credentialsList.length" >
            <button class="cred-row-btn" (click)="handleDeleteAll()">
              <fa-icon [icon]="deleteIcon" class="pe-2 text-white" title="Delete Icon"></fa-icon>
              Delete All
            </button>
          </div>
          <div class="col-md-3">
            <button class="cred-row-btn" routerLink="/welcome">
              <fa-icon [icon]="homeIcon" class="pe-2 text-white" title="Home Icon"></fa-icon>
              Home
            </button>
          </div>
          <div class="col-md-3">
            <button class="cred-row-btn" routerLink="/register">
              <fa-icon [icon]="plusIcon" class="pe-2 text-white" title="Plus Icon"></fa-icon>
              New
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col" *ngIf="!credentialsList.length">
            <h4>No credentials associated to your account.</h4>
          </div>

          <div class="col">
            <div class="table-responsive" *ngIf="credentialsList.length">
              <table class="table table-striped self-service-content">
                <tbody>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Details</th>
                  <th scope="col">Status</th>
                  <th scope="col" class="text-center">
                    Operations
                  </th>
                </tr>
                <tr *ngFor="let item of credentialsList; let i = index"
                    [ngClass]="{'cred-row':true,'last-cred-row':i===credentialsList.length-1}"
                    [attr.data-cred-id]="item.credId">
                  <td>
                    {{(credTypeMap[item?.credType] || {}).name || item?.credType}}
                  </td>
                  <td>
                    {{item?.credValue}}
                    <br/>
                    <span
                      class="addedTS">Added {{getCustomDateFormat(item?.createdDatetime || item?.modifiedDatetime)}}</span>
                  </td>
                  <td>
                    <div class="row">
                      <select class="self-service-content col rounded form-select" *ngIf="statusMap[item.status]" [ngModel]="item.status"
                              (change)="onStatusChange(item, $event.target.value)">
                        <option *ngFor="let status of statuses" [value]="status.value">{{status.label}}</option>
                      </select>
                      <span class="col statusText" *ngIf="!statusMap[item.status]">{{('' + item.status).toLowerCase()}}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <button class="table-btn px-3" (click)="handleDeleteCred(item)">
                      <i class="fas fa-trash-alt" alt="Delete "></i>
                      <fa-icon [icon]="deleteIcon" class="text-white" title="Delete Icon"></fa-icon>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </div>
</div>
