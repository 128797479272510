import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import { DisplayService } from './display.service';
import {CookieService} from "./cookie.service";
import {GlobalService} from "./global.service";
import {DeviceprintService} from "./deviceprint.service";

@Injectable({
  providedIn: 'root'
})
export class InitService {

  loginEnabled: boolean = false;
  settingsMsg: boolean = false;
  errorMsg = "";

  constructor(
    private http: HttpClient,
    private displayService: DisplayService,
    private cookieService: CookieService,
    private deviceprintService: DeviceprintService,
    private globalService: GlobalService
  ) {}

  public onUserNameLogin(postData): Observable<any> {
    let userName = (postData["subject"] || "").toLowerCase();
    if (userName) {
      let tdiValFromCookie = this.cookieService.getCookie("tdi-" + this.displayService.obfuscate("SSP-TDI-" + userName));
      if (tdiValFromCookie) {
        postData['trustedDeviceID'] = tdiValFromCookie;
      }
      let fidoID = this.cookieService.getCookie("fid-" + this.displayService.obfuscate("ssp-fido-identifier-" + this.displayService.obfuscate(userName)));
      if (fidoID) {
        let authContext = [];
        authContext.push({key: "fidoIdentifier", value: fidoID});
        postData['authContext'] = authContext;
      }
    }

    return this.http.post(this.displayService.serviceUrl + 'auth/v1/authenticate', postData);
  }

  /**
   * Configure the authentication parameters according to the risk type
   * @param userName
   * @param rememberMe
   */
  private getAuthenticatePostData(userName: string, rememberMe: boolean): any {
    const data = {
      channel: 'web',
      action: 'authenticate'
    };

    switch (this.deviceprintService.riskType) {
      case "IARISK": {
        data['device'] = { signature: {"iaAuthData": this.deviceprintService.fingerPrintDna} };
        break;
      }
      case "AARISK": {
        data['device'] = { signature: this.deviceprintService.fingerPrintDna };
        break;
      }
      case "RSARISK": {
        data['clientContext'] = [
          { key: "deviceprint", value: this.deviceprintService.fingerPrintDna },
          { key: "rememberme", value: rememberMe }
        ];
        break;
      }
      default: {
        data['device'] = { signature: this.deviceprintService.fingerPrintDna };
        break;
      }
    }
    if (rememberMe) {
      data['rememberMe'] = true;
    }
    if (userName) {
      data['subject'] = userName;
    }

    /* This is needed, for oauth2 types of authentication */
    if(this.displayService.flowState) {
      data['LATEST-FLOW-STATE'] = '[LATEST-FLOW-STATE]';
    }
    return data;
  }

  /**
   * Authenticate payload requires device fingerprint, which may be delayed
   * @param userName
   * @param rememberMe
   */
  authenticate(userName: string, rememberMe: boolean): Observable<any> {
    this.globalService.setRememberDeviceEnabled(false);
    this.displayService.userName = userName;
    let data = this.getAuthenticatePostData(userName, rememberMe);

    return this.deviceprintService.getFingerprintComplete().pipe(
      concatMap(() => this.onUserNameLogin(data))
    )
  }
}
