<div class="row justify-content-center visible" *ngIf="!isAuthenticated">
  <div class="userInfoContainer col-auto mx-3" [ngClass]="globalService.allowUserReset ? 'pointerOnHover' : ''">
    <div *ngIf="userName && !title">
      <div *ngIf="!globalService.allowUserReset">User {{userName}}</div>
      <div *ngIf="globalService.allowUserReset" (click)="notUser()">Not {{userName}}?</div>
    </div>
    <div *ngIf="title">
      <div (click)="notUser()">{{title}}</div>
    </div>
  </div>
</div>
