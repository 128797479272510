import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {OtpService} from "../service/otp.service";
import {DisplayService} from "../service/display.service";
import {FactorselectionService} from "../service/factorselection.service";

/**
 * Component to handle OTPs for SMS, IVR, and EMAIL
 */
@Component({
  selector: 'app-otp-general',
  templateUrl: './otp-general.component.html'
})
export class OtpGeneralComponent implements OnInit, OnDestroy {
  @Input() niceOtpName: string;
  @Input() credType: string;
  @Input() resendEnabled: boolean = true;

  multiFactor = false;
  userName = null;
  registerOtp = false;
  credId = '';
  otpOptions: string[] = [];
  verifyOtpForm: UntypedFormGroup;
  errorDiv  = false;
  errorMsg: string;
  factorSelection = false;
  selectedCredValue: string;
  cachedFlowState: string;
  statusMsg: string;
  otpEntry: string;
  ready: boolean = false;
  pageSubs: Subscription[] = new Array<Subscription>();
  resendIcon = faSyncAlt;
  otpSent: boolean = false;

  isRegisterFlow: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private otpService: OtpService,
    private displayService: DisplayService,
    private factorselectionService: FactorselectionService) { }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.verifyOtpForm = this.formBuilder.group({otp: ['', Validators.required]});
    this.registerOtp = this.displayService.registerOtp;
    this.otpOptions = this.displayService.otpOptions;

    this.isRegisterFlow = location.href.endsWith("register/"+this.credType);

    if (this.isRegisterFlow) {
      this.registerFlow();
    } else {
      this.factorFlow();
    }
  }

  private factorFlow() {
    this.factorSelection = this.displayService.factorSelection;
    this.otpOptions.forEach(function(this: any, value) {
      if (value['default'] === true) {
        this.credId = value['credId'];
        this.credType = value['credType'];
        this.selectedCredValue = value['credValue'];
      }
    }.bind(this));
    if (this.otpOptions.length === 1) {
      this.multiFactor = false;
      this.credId = (<any>this.otpOptions[0]).credId;
      this.credType = (<any>this.otpOptions[0]).credType;
      this.selectedCredValue = (<any>this.otpOptions[0]).credValue;
      this.onGenerate();
    } else {
      this.multiFactor = true;
    }
  }

  private registerFlow() {
    this.resendEnabled = false;
    this.multiFactor = false;
    this.factorSelection = true;
    let data = this.router.lastSuccessfulNavigation?.extras?.state?.data;
    this.selectedCredValue = data?.credValue;
    this.credId = data?.credId;
    this.credType = data?.credType;
    this.otpSent = true;
    this.ready = true;
  }

  selectedCredential(event: any) {
    if (event) {
      this.credId = event.credId;
      this.credType = event.credType;
      this.selectedCredValue = event.credValue;
    }
    this.multiFactor = false;
    this.onGenerate();
  }

  onGenerate() {
    this.statusMsg = `Sending security code to ${this.selectedCredValue} . . .`;
    this.ready = false;
    this.otpSent = false;
    this.cachedFlowState = this.displayService.flowState;
    this.pageSubs.push(
      this.generateOtp()
    );
  }

  private generateOtp() {
    return this.otpService.generateOtp(this.credId, this.credType).subscribe({
      next: (data) => {
        this.otpSent = true;
        if (data['nextaction'] === `${this.credType.toUpperCase()}_OTP_AUTH`) {
          this.errorDiv = false;
          this.displayService.flowState = data['flowState'];
        } else {
          this.errorDiv = true;
          this.errorMsg = `Invalid action ${data['nextaction']}`;
        }
        this.ready = true;
      },
      error: (err) => {
        this.ready = true;
        this.otpSent = false;
        this.errorDiv = this.displayService.errorDiv;
        this.errorMsg = this.displayService.errorMsg;
      }
    })
  }

  onVerify() {
    this.ready = false;
    this.errorDiv = false;
    this.errorMsg = null;
    this.pageSubs.push(this.verifyOtp());
  }

  verifyOtp() {
    return this.otpService.verifyOtp(this.otpEntry, this.credId, this.isRegisterFlow).subscribe({
      next: (data) => {
        if (this.isRegisterFlow) {
          this.router.navigateByUrl("self-service", {state: {newCredAdded: true, newCredName: this.selectedCredValue}});

        } else {
          this.displayService.routeActions(data);
        }
      },
      error: (err) => {
        this.errorMsg = this.displayService.errorMsg;
        this.errorDiv = this.displayService.errorDiv;
        this.ready = true;
      }
    })
  }

  onResendOTP() {
    this.errorDiv = false;
    this.errorMsg = null;
    if (this.cachedFlowState) {
      this.displayService.flowState = this.cachedFlowState;
    }
    this.onGenerate();
  }

  onBack() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  onOtpEntryChange(event) {
    this.otpEntry = event.otpInput;
    if (event.submit) {
      this.onVerify();
    }
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub: Subscription) => {
      sub?.unsubscribe();
    });
  }
}
