import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class OtpService {


  constructor(
    private http: HttpClient,
    private displayService: DisplayService,
  ) { }

  public generateOtp(credID, credType) {
    const postData = {
      credId: credID,
      credType,
      action: 'login',
      deviceTag: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/OTPGenerator', postData);
  }

  public verifyOtp(otp, credID, isRegister?: boolean) {
    const postData = {
      credId: credID,
      otp
    };
    if (isRegister) {
      postData["userAccessToken"] = true;
    }
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/OTPVerifier', postData);
  }

  public registerOtp(credType, credValue) {
    const postData = {
      credType,
      credValue,
      default: true,
      deviceTag: true
    };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/OTPRegistrar', postData);
  }
}
